import moment from 'moment';
import {
  UPDATE_BOOKING,
  SET_ESTIMATE,
  SET_DISCOUNT_CODE,
  CLEAR_BOOKING_INFO,
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_ERROR,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_ERROR,
  ERROR_QUOTE,
  SET_QUOTE,
  START_QUOTE,
  CREATE_BOOKINGS_ERROR,
  CLEAN_BOOKINGS_ERROR,
  LEAD_ERROR,
  LEAD_LOADING,
  LEAD_SUCCESS,
} from '../constants/booking';
import {
  TEMP_UPDATE_BOOKING,
  TEMP_SET_ESTIMATE,
  TEMP_SET_DISCOUNT_CODE,
  UPDATE_TEMP_EDIT_BOOKING_SUCCESS,
} from '../constants/tempBooking';
import { CLEAR_USER_INFO, UPDATE_USER_PROFILE } from '../constants/user';
import { CLEAR_MAPBOX_INFO } from '../constants/mapbox';
import { CLEAR_PAYMENT_INFO } from '../constants/payment';
import { CLEAR_TIP } from '../constants/tip';
import { CLEAR_QUERY_INFO } from '../constants/query';
import BookingService from '../../services/BookingService';
import {
  getLocationDetails,
  getCorrectConstant,
} from '../../utils/bookingUtil';
import { calculateRequiredMates } from '../../utils/calculateRequiredMates';
import { calculateVolume } from '../../utils/calculateVolume';

const bookingService = new BookingService();

/**
 * Updates the data inside the correct booking reducer
 * @param {*} bookingData the data that will be updated
 * @param {boolean} editMode determines if we're updating on the booking reducer or temp booking reducer
 */
export const updateBookingData = (bookingData, editMode = false) => {
  return async (dispatch) => {
    await dispatch({
      type: getCorrectConstant(editMode, UPDATE_BOOKING, TEMP_UPDATE_BOOKING),
      bookingData,
    });
    dispatch(getEstimate(editMode));
  };
};

/**
 * Gets a discount response from the backend
 */
export const getDiscount = (discountCode) => {
  return async (_, getState) => {
    const { bookingReducer } = getState();
    try {
      const { date } = bookingReducer;
      const resp = await bookingService.fetchDiscount(
        discountCode,
        moment(date).toISOString()
      );
      return resp;
    } catch (error) {
      return null;
    }
  };
};

/**
 * Gets the estimate value for the current or editing booking
 * @param {boolean} editMode determines if we're updating on the booking reducer or temp booking reducer
 */
export const getEstimate = (editMode) => {
  return async (dispatch, getState) => {
    const {
      bookingReducer,
      tempBookingReducer,
      mapboxReducer,
      tempMapboxReducer,
      geoReducer,
    } = getState();
    const {
      clientAsMate,
      date,
      drivingDuration,
      discountCode,
      dropoffFloor,
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      dropoffParkingIsNear,
      dropoffBuildingType,
      justMates,
      dropoff,
      pickup,
      pickupFloor,
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      pickupParkingIsNear,
      pickupBuildingType,
      rideAlong,
      selectedFurnitures,
      addons,
      packingBoxes = 0,
      partnerAddons = [],
      time,
    } = !editMode ? bookingReducer : tempBookingReducer;
    const { distance, duration } = !editMode
      ? mapboxReducer
      : tempMapboxReducer;
    const { tax, timezone } = geoReducer;

    const pickupLocationDetails = getLocationDetails(
      'pickup',
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      pickupFloor,
      pickupBuildingType,
      pickupParkingIsNear
    );
    const dropoffLocationDetails = getLocationDetails(
      'dropoff',
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      dropoffFloor,
      dropoffBuildingType,
      dropoffParkingIsNear
    );
    const addonsData = { ...addons, packingBoxes, partnerAddons };
    const volume = calculateVolume(selectedFurnitures);

    const bookingData = {
      drivingDuration,
      travelDuration: duration / 60 || 1,
      estimatedDistance: parseFloat(distance),
      selectedFurnitures: { furnitures: selectedFurnitures },
      addons: addonsData,
      mateCount: calculateRequiredMates(volume),
      locationDetails: [pickupLocationDetails, dropoffLocationDetails],
      rideAlong,
      movingDate: moment(date).toISOString() || moment().toISOString(),
      time,
      timezone,
      discountCode,
      clientAsMate,
      justMates,
      tax: tax.rate,
      pickupAddress: pickup,
      dropoffAddress: dropoff,
    };
    try {
      const estimate = await bookingService.fetchEstimate(bookingData);
      if (estimate && !estimate.discountCode) {
        dispatch({
          type: getCorrectConstant(
            editMode,
            SET_DISCOUNT_CODE,
            TEMP_SET_DISCOUNT_CODE
          ),
          discountCode: null,
        });
      }
      dispatch({
        type: getCorrectConstant(editMode, SET_ESTIMATE, TEMP_SET_ESTIMATE),
        estimate,
      });
    } catch (error) {
      dispatch({
        type: getCorrectConstant(editMode, SET_ESTIMATE, TEMP_SET_ESTIMATE),
        estimate: {
          amount: 0,
          currency: 'CAD',
          tax: 0,
          longDistance: false,
        },
      });
    }
  };
};

/**
 * Gets the estimated duration for the current booking
 * @param {boolean} editMode determines if we're updating on the booking reducer or temp booking reducer
 */
export const getDuration = (
  editMode = false,
  calculateDrivingDuration = false
) => {
  return async (dispatch, getState) => {
    const {
      bookingReducer,
      tempBookingReducer,
      mapboxReducer,
      tempMapboxReducer,
      geoReducer,
    } = getState();
    const {
      drivingDuration,
      clientAsMate,
      date,
      discountCode,
      dropoffFloor,
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      dropoffBuildingType,
      dropoffParkingIsNear,
      justMates,
      pickupFloor,
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      pickupBuildingType,
      pickupParkingIsNear,
      rideAlong,
      selectedFurnitures,
      pickup,
      dropoff,
      addons,
      packingBoxes = 0,
    } = !editMode ? bookingReducer : tempBookingReducer;
    const { distance, duration } = !editMode
      ? mapboxReducer
      : tempMapboxReducer;
    const pickupLocationDetails = getLocationDetails(
      'pickup',
      pickupAptUnit,
      pickupHasElevator,
      pickupHasParking,
      pickupFloor,
      pickupBuildingType,
      pickupParkingIsNear
    );
    const dropoffLocationDetails = getLocationDetails(
      'dropoff',
      dropoffAptUnit,
      dropoffHasElevator,
      dropoffHasParking,
      dropoffFloor,
      dropoffBuildingType,
      dropoffParkingIsNear
    );
    const addonsData = { ...addons, packingBoxes };
    const { timezone } = geoReducer;
    const volume = calculateVolume(selectedFurnitures);

    const bookingData = {
      drivingDuration: calculateDrivingDuration ? null : drivingDuration,
      travelDuration: duration / 60 || 1,
      estimatedDistance: parseFloat(distance),
      selectedFurnitures: { furnitures: selectedFurnitures },
      addons: addonsData,
      mateCount: calculateRequiredMates(volume),
      locationDetails: [pickupLocationDetails, dropoffLocationDetails],
      rideAlong,
      movingDate: moment(date).toISOString() || moment().toISOString(),
      discountCode,
      clientAsMate,
      justMates,
      timezone,
      pickupAddress: pickup,
      dropoffAddress: dropoff,
    };

    try {
      const moveDuration = await bookingService.fetchDuration(bookingData);
      dispatch(
        updateBookingData(
          {
            drivingDuration: moveDuration.driving_duration,
            mateCount: moveDuration.mate_count,
            estimatedDuration: moveDuration.duration,
            isDurationWithinLimit: moveDuration.is_duration_within_limit,
          },
          editMode
        )
      );

      return moveDuration || -1;
    } catch (error) {
      console.error(error);
      return -1;
    }
  };
};

/**
 *
 * @param {*} customerData The customer payment data for the current booking
 * @param {*} userId The current auth0 user id used for the booking, should be eliminated in the future
 * @returns
 */
export const createBooking = (
  customerData,
  userId,
  billingInformationValues
) => {
  return async (dispatch, getState) => {
    try {
      const {
        bookingReducer,
        mapboxReducer,
        languageReducer,
        tipReducer,
        geoReducer,
      } = getState();
      const {
        bookingType,
        clientAsMate,
        comment,
        date,
        discount,
        discountCode,
        dropoffFloor,
        dropoffAptUnit,
        dropoffHasElevator,
        dropoffHasParking,
        dropoffParkingIsNear,
        justMates,
        pickupFloor,
        pickupAptUnit,
        pickupHasElevator,
        pickupHasParking,
        pickupParkingIsNear,
        pickupBuildingType,
        dropoffBuildingType,
        rideAlong,
        selectedFurnitures,
        addons,
        packingBoxes = 0,
        time,
        resourceId,
        pickup,
        dropoff,
        rooms,
        boxes,
        partnerAddons = [],
      } = bookingReducer;
      const { distance, duration } = mapboxReducer;

      const { tax, timezone } = geoReducer;

      const tipData = {
        value: tipReducer.value.replace(',', '.'),
        isPaid: false,
      };

      const pickupLocationDetails = getLocationDetails(
        'pickup',
        pickupAptUnit,
        pickupHasElevator,
        pickupHasParking,
        pickupFloor,
        pickupBuildingType,
        pickupParkingIsNear
      );
      const dropoffLocationDetails = getLocationDetails(
        'dropoff',
        dropoffAptUnit,
        dropoffHasElevator,
        dropoffHasParking,
        dropoffFloor,
        dropoffBuildingType,
        dropoffParkingIsNear
      );
      const addonsData = { ...addons, partnerAddons, packingBoxes };
      const volume = calculateVolume(selectedFurnitures);

      const data = {
        travelDuration: duration / 60 || 1,
        selectedFurnitures: { furnitures: selectedFurnitures },
        addons: addonsData,
        rooms,
        boxes,
        movingDate: (moment(date) || moment()).toISOString(),
        discountCode,
        bookingType,
        clientAsMate,
        comment,
        date: moment(date).toISOString(),
        discount,
        dropoffAddress: {
          unit: dropoff.unit || '',
          street: dropoff.street || '',
          postalCode: dropoff.postalCode || '',
          city: dropoff.city || '',
          province: dropoff.province || '',
          country: dropoff.country || '',
          aptUnit: dropoffAptUnit || '',
          fullAddress: dropoff.fullAddress || '',
        },
        estimatedDistance: parseFloat(distance),
        justMates,
        locationDetails: [pickupLocationDetails, dropoffLocationDetails],
        mateCount: calculateRequiredMates(volume),
        pickupAddress: {
          unit: pickup.unit || '',
          street: pickup.street || '',
          postalCode: pickup.postalCode || '',
          city: pickup.city || '',
          province: pickup.province || '',
          country: pickup.country || '',
          aptUnit: pickupAptUnit || '',
          fullAddress: pickup.fullAddress || '',
        },
        rideAlong,
        userId: window.btoa(userId),
        time: moment(time).toISOString(),
        timezone: timezone,
        language: languageReducer.locale,
        calendarId: resourceId,
        tax: tax.rate,
        billingInformation: {
          firstName: billingInformationValues.firstName,
          lastName: billingInformationValues.lastName,
          phone: billingInformationValues.phone,
          email: billingInformationValues.email,
          survey: billingInformationValues.survey,
          surveyOther: billingInformationValues.surveyOther,
          billingAddress: {
            streetName: billingInformationValues.streetName,
            aptUnit: billingInformationValues.aptUnit,
            city: billingInformationValues.city,
            postalCode: billingInformationValues.postalCode,
            province: billingInformationValues.province,
            country: billingInformationValues.country,
          },
        },
        isGuest: !userId,
      };
      // Clear the error message
      await dispatch({ type: CREATE_BOOKINGS_ERROR, payload: null });
      return await bookingService.createBooking(data, tipData, customerData);
    } catch (error) {
      let errorMessage = 'error.load';
      if (error.response && error.response.status === 400) {
        errorMessage = 'error.payment';
        if (error.response.data) {
          errorMessage = error.response.data.detail || errorMessage;
        }
      }
      await dispatch({
        type: CREATE_BOOKINGS_ERROR,
        payload: errorMessage,
      });
    }
  };
};

function getLocation(type, data) {
  return getLocationDetails(
    type,
    data[type + 'AptUnit'],
    data[type + 'HasElevator'],
    data[type + 'HasParking'],
    data[type + 'Floor'],
    data[type + 'BuildingType'],
    data[type + 'ParkingIsNear']
  );
}

export const createQuote = (email = null) => {
  return async (dispatch, getState) => {
    try {
      await dispatch({ type: START_QUOTE });
      const {
        bookingReducer,
        languageReducer,
        mapboxReducer,
        geoReducer,
        userReducer,
        queryReducer,
      } = getState();
      const { firstName, lastName, phone, email: leadEmail } = userReducer;
      const { distance, duration } = mapboxReducer;
      const { timezone } = geoReducer;
      const { discount } = queryReducer;
      const pickupLocationDetails = getLocation('pickup', bookingReducer);
      const dropoffLocationDetails = getLocation('dropoff', bookingReducer);
      const details = {
        ...bookingReducer,
        timezone,
        email: email || leadEmail,
        phone: phone || '',
        firstName: firstName || '',
        lastName: lastName || '',
        estimatedDistance: distance,
        travelDuration: duration,
        language: languageReducer.locale,
        locationDetails: [pickupLocationDetails, dropoffLocationDetails],
        tax: bookingReducer.estimate.tax,
        movingDate:
          moment(bookingReducer.date).toISOString() || moment().toISOString(),
        discountCode: bookingReducer.discountCode || discount,
      };
      const data = bookingReducer.quote.id
        ? await bookingService.updateQuote(email, details)
        : await bookingService.createQuote(email, details);
      await dispatch({
        type: SET_QUOTE,
        id: data.id,
        email: data.email,
        shortId: data.shortId,
      });
    } catch (error) {
      await dispatch({
        type: ERROR_QUOTE,
        payload: error,
      });
      return error;
    }
  };
};

export const fetchPreviousBookings = (page, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: GET_BOOKINGS,
      });
      await dispatch({ type: UPDATE_TEMP_EDIT_BOOKING_SUCCESS });
      const bookings = await bookingService.fetchPreviousBookings(
        page,
        perPage,
        accessToken
      );
      await dispatch({
        type: GET_BOOKINGS_SUCCESS,
        bookings: bookings.data,
        total: bookings.total,
      });
    } catch (error) {
      await dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
      return error;
    }
  };
};

export const fetchUpcomingBookings = (page, perPage = 10, accessToken) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: GET_BOOKINGS,
      });
      await dispatch({ type: UPDATE_TEMP_EDIT_BOOKING_SUCCESS });
      const bookings = await bookingService.fetchUpcomingBookings(
        page,
        perPage,
        accessToken
      );
      await dispatch({
        type: GET_BOOKINGS_SUCCESS,
        bookings: bookings.data,
        total: bookings.total,
      });
    } catch (error) {
      await dispatch({
        type: GET_BOOKINGS_ERROR,
        payload: error,
      });
      return error;
    }
  };
};

export const clearStore = () => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: CLEAR_BOOKING_INFO,
      });
      await dispatch({
        type: CLEAR_MAPBOX_INFO,
      });
      await dispatch({
        type: CLEAR_USER_INFO,
      });
      await dispatch({
        type: CLEAR_PAYMENT_INFO,
      });
      await dispatch({
        type: CLEAR_TIP,
      });
      await dispatch({
        type: CLEAR_QUERY_INFO,
      });
    } catch (error) {
      return error;
    }
  };
};

export const clearPaymentErrorMessage = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAN_BOOKINGS_ERROR,
    });
  };
};

// only used for testing
export const setBookingState = (state) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_STATE',
      state,
    });
  };
};

export const getBiggestFurniture = (selectedFurnitures) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  return (dispatch, getState) => {
    return selectedFurnitures.reduce((max, furniture) => {
      if (!max) {
        return furniture;
      }
      return max.cubic < furniture.cubic ? furniture : max;
    }, null);
  };
};

export const cancelBooking = (id, { email, shortId, cipher, accessToken }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_BOOKING,
      });
      await bookingService.cancelBooking(id, {
        email,
        shortId,
        cipher,
        accessToken,
      });
      dispatch({
        type: CANCEL_BOOKING_SUCCESS,
        id,
      });
    } catch (err) {
      dispatch({
        type: CANCEL_BOOKING_ERROR,
        payload: err,
      });
    }
  };
};

export const sendLead = (email, firstName, phoneNumber) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: LEAD_LOADING,
      });
      const { queryReducer } = getState();
      const body = {
        email,
        firstName,
        phoneNumber,
        discountCode: queryReducer.discount || '',
      };
      const [name, ...lastName] = firstName.split(' ');
      dispatch({
        type: UPDATE_USER_PROFILE,
        profileData: {
          email,
          firstName: name,
          lastName: lastName.join(' '),
          phone: phoneNumber,
        },
      });
      await bookingService.sendLead(body);
      dispatch({
        type: LEAD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LEAD_ERROR,
        payload: error,
      });
      return error;
    }
  };
};
